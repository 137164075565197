@import 'colors.less';

.tab {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  color: @grey6;
}

.tab-active {
  border-bottom: 2px solid @primary;
  color: @primary;
}
