// vars
@footer: #222222;
@black: #000000;
@grey8: #444444;
@grey7: #555555;
@grey6: #777777;
@grey5: #a3a3a3;
@grey4: #d7d7d7;
@grey3: #d0d5db;
@grey2: #e7ebef;
@grey1: #f5f5f5;
@purple: #540087;
@blue: #1253FA;
@lightBlue: #EDF8FF;
@white: #ffffff;
@brandDark: #9a1616;
@brand: #bb2e2e;
@brandSuperLight: #f5fcf6;
@brandExtraLight: #EAF8ED;
@brandFivePercent: rgba(187, 46, 46, 0.05);
@brandLight: #c65252;
@brandLightTenPercent: #4ec2631a;
@red: #ec0000;
@green: #33ec00;
@orange: #ecb500;
@primary: @brand;

// styles colors
.primary-color {
    color: @brand;
}
.brand-dark-color {
    color: @brandDark;
}
.brand-light-color {
    color: @brandLight;
}
.brand-5Percent-color {
    color: @brandFivePercent;
}
.grey-7-color {
    color: @grey7;
}
.grey-6-color {
    color: @grey6;
}
.grey-5-color {
    color: @grey5;
}
.grey-4-color {
    color: @grey4;
}
.grey-3-color {
    color: @grey3;
}
.grey-2-color {
    color: @grey2;
}
.grey-1-color {
    color: @grey1;
}

.black-color {
    color: @black;
}

.red-color {
    color: @red;
}

.green-color {
    color: @green;
}

.orange-color {
    color: @orange;
}

.white-color {
    color: @white;
}

.purple-color {
    color: @purple;
}

.blue-color {
    color: @blue;
}

// styles background-colors
.black-background-color {
    background-color: @black;
}

.white-background-color {
    background-color: white;
}

.primary-background-color {
    background-color: @brand;
}

.primary-extra-light-background-color {
    background-color: @brandExtraLight;
}

.grey-1-background-color {
    background-color: @grey1;
}

.grey-4-background-color {
    background-color: @grey4;
}
