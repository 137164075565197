.p4 {
  padding: 4px;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p40 {
  padding: 40px;
}

.p50 {
  padding: 50px;
}

.ph4 {
  padding-left: 4px;
  padding-right: 4px;
}

.ph10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph12 {
  padding-left: 12px;
  padding-right: 12px;
}

.ph20 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph40 {
  padding-left: 40px;
  padding-right: 40px;
}


.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pv20-ph40 {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv10-ph20 {
  padding: 10px 20px;
}

.pv6-ph12 {
  padding: 6px 12px;
}
