@import './common/colors.less';

@pageHeaderHeight: 110px;

.navigation-button {
  margin-left: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  shape-rendering: geometricPrecision;
  border: 1px solid white;
  border-radius: 100%;
}

.navigation-button-bordered {
  border: 1px solid white;
  border-radius: 100%;
}

.navigation-icon {
  font-size: 18px;
  color: white
}

.page-header {
  height: @pageHeaderHeight;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.page-header-block {
  margin: 36px 20px 24px 20px;
  width: 100%;
  height: 72px;
  border-radius: 10px;
  background-color: white;
}

.navigation-item() {
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 12px;
}

.navigation-item {
  .navigation-item();
  color: @grey7
}

.selected-navigation-item {
  .navigation-item();
  color: white;
  background-color: @primary;
}

.right-menu-item() {
  //width: 60px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 60px;
  border: 1px solid @grey2;
  padding-left: 10px;
  padding-right: 10px;
}

.right-menu-item {
  .right-menu-item();
  color: @grey7
}

.selected-right-menu-item {
  .right-menu-item();
  border-color: @primary;
  background-color: white;
}

.icon() {
  transition: 0.2s ease-in-out;
}
