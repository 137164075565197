@import '../assets/fonts/font.css';
@import './common/colors.less';
@import './common/text.less';
@import './common/tabs.less';
@import './common/alignment.less';
@import './common/borders.less';
@import './common/transitions.less';
@import './common/paddings.less';
@import './common/margins.less';
@import './common/buttons.less';
@import './common/sizes.less';

@import './navigation.less';
@import './pagination.less';

html {
    overflow: auto;
}

* {
    font-family: "Poppins", sans-serif;
}

body {
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;          /* Firefox */
    image-rendering: -o-crisp-edges;            /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
    margin: 0;
}

//code {
//    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//        monospace;
//}

table {
    width: 100%;
    border-spacing: 0;
    margin: 0 auto;
    border-collapse: collapse;
}

th, td {
    margin: 0;
    padding: 0.5rem;
}

.ant-upload {
    padding: 0 !important;
}

.scroll-block {
    overflow: auto !important;
    scrollbar-color: @brandSuperLight transparent;
    scrollbar-width: thin;
}

.scroll-block::-webkit-scrollbar {
    height: 10px;
    width: 8px;
}

.scroll-block::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

.scroll-block::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: @brandSuperLight;
}

.common-page {
    width: 100%;
    height: calc(100vh - @pageHeaderHeight);
}

.common-block {
    background-color: white;
    border-radius: 10px;
    margin: 12px 20px 36px 20px;
    padding: 10px;
}

.common-block-header {
    margin: 12px 20px;
}

.spinner {
    width: 100%;
}

.pointer {
    cursor: pointer;
}
