.mt2 {
  margin-top: 2px;
}

.mt4 {
  margin-top: 4px;
}

.mt6 {
  margin-top: 6px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt100 {
  margin-top: 100px;
}

.ml4 {
  margin-left: 4px
}

.ml6 {
  margin-left: 6px
}

.ml10 {
  margin-left: 10px
}

.ml20 {
  margin-left: 20px
}

.ml30 {
  margin-left: 30px
}

.ml40 {
  margin-left: 40px
}

.mb4 {
  margin-bottom: 4px
}

.mb10 {
  margin-bottom: 10px
}

.mb20 {
  margin-bottom: 20px
}

.mb30 {
  margin-bottom: 30px
}

.mh10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.mr4 {
  margin-right: 4px
}

.mr6 {
  margin-right: 6px
}

.mr10 {
  margin-right: 10px
}

.mr20 {
  margin-right: 20px
}

.mr30 {
  margin-right: 30px
}
