@import './common/colors.less';

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  padding-right: 20px;
}

.pagination-item() {
  margin-left: 10px;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
}

.pagination-item {
  .pagination-item();
  color: @grey6;
  border: 1px solid @grey2;
  background-color: @grey1;
}

.selected-pagination-item {
  .pagination-item();
  color: white;
  border: 1px solid @brandExtraLight;
  background-color: @brand;
}
