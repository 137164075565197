.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.vertical-top {
  display: flex;
  align-items: flex-start;
}

.vertical-bottom {
  display: flex;
  align-items: flex-end;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.horizontal-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.horizontal-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vertical-space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.inline {
  display: inline-block;
}
