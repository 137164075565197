@import './colors.less';

@buttonHeight: 36px;

.button-label {
  font-weight: 600;
  font-size: 16px;
  margin-top: 1px;
}

.inverted-button {
  height: @buttonHeight;
  border: 1px solid @primary;
  color: @primary;
  background-color: transparent;
}

.primary-button {
  height: @buttonHeight;
  background-color: @primary;
  color: white;
  border-color: @primary;
}

.default-button {
  height: @buttonHeight;
  border-color: white;
  color: @primary;
  box-shadow: none;
}

.danger-button {
  height: @buttonHeight;
  color: @red;
  border-color: @red;
  background-color: transparent;
}

.danger-button:hover {
  background-color: @red !important;
  border-color: @red;
  color: white;
}

.custom-button-icon {
  margin-right: 8px;
  font-size: 22px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.danger-button-filled {
  height: @buttonHeight;
  background-color: @red;
  color: white;
  border-color: @red;
}

.danger-button-filled:hover, .danger-button-filled:focus {
  background-color: transparent;
  border-color: @red;
  color: @red;
}

.primary-icon-pressed {
  color: @primary;
  background-color: @brandFivePercent;
  border-radius: 4px;
}

.hover {
  &:hover{
    background-color: @lightBlue;
  }
}