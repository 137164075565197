.bold {
  font-weight: 600
}

.underline {
  text-decoration: underline;
}

.extra-bold {
  font-weight: 700
}

.word-break-all {
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-shadow {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
}

.input-label {
  font-weight: 700;
  font-size: 16px;
}

.input-sub-label {
  font-weight: 700;
  font-size: 14px;
}

.input-description {
  font-weight: 500;
  font-size: 13px;
}

.input-content {
  font-weight: 600;
  font-size: 16px;
}

.select-content {
  font-weight: 600;
  font-size: 14px;
}

.select-option {
  font-weight: 600;
  font-size: 14px;
}

.modal-header {
  font-weight: 600;
  font-size: 18px;
}

.modal-description {
  font-weight: 600;
  font-size: 16px;
}

.table-header {
  font-weight: 600;
  font-size: 17px;
}

.table-content {
  font-weight: 600;
  font-size: 15px;
}

.checkbox-label {
  font-weight: 600;
  font-size: 16px;
}

.pipeline-card-status-time {
  font-weight: 600;
  font-size: 12px;
}

.h1 {
  font-weight: 700;
  font-size: 28px;
}

.h2 {
  font-weight: 700;
  font-size: 20px;
}

.h3 {
  font-weight: 600;
  font-size: 18px;
}

.h4 {
  font-weight: 600;
  font-size: 16px;
}

.h5 {
  font-weight: 400;
  font-size: 14px;
}

.h6 {
  font-weight: 400;
  font-size: 12px;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}

.fs10 {
  font-size: 10px;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fs26 {
  font-size: 26px;
}

.fs28 {
  font-size: 28px;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
