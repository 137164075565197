@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: local('Poppins'), url(./Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: local('Poppins'), url(./Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: local('Poppins'), url(./Poppins-SemiBold.ttf) format('truetype');
}
